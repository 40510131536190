/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  DashboardOutlined,
  LaptopOutlined,
  LogoutOutlined,
  QuestionOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import styles from '@styles/Header/user-drawer-nav.module.scss';
import Link from 'next/link';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { GoldButton } from 'sailor-ui/GoldButton';
import { HelpCenterMenu } from 'sailor-ui/HelpCenter';
import { usePlans } from 'services';
import MainDrawer from '../MainDrawer';
import UserAvatar from './UserAvatar';
import { useConfig } from 'providers';

const UserDrawerNav: React.FC = () => {
  const { formatMessage } = useIntl();
  const { auth0LogoutReturnTo, mdcAccountUrl, theboatAppUrl } = useConfig();
  const f = (id: string) => formatMessage({ id: `header.menu.user.nav.${id}` });

  const { logout } = useAuth0();
  const { user } = usePlans();

  const [helpCenterDrawer, setHelpCenterDrawer] = useState(false);

  const showDrawer = () => {
    setHelpCenterDrawer(true);
  };

  const onClose = () => {
    setHelpCenterDrawer(false);
  };

  const handleLogout = () => {
    const returnTo = auth0LogoutReturnTo;

    logout({ returnTo });
  };

  return (
    <>
      <div className={styles.user__nav__container}>
        <div className={styles.nav__head}>
          <div className={styles.user__detail}>
            <UserAvatar />

            <p className={styles.user__name}>{user?.display_name}</p>
          </div>

          {/* <div className={styles.user__trail}>
            <GoldButton />
            <TrailLeftDays user={user} />
          </div> */}
        </div>

        <ul className={styles.nav__menu}>
          <li>
            <Link href={theboatAppUrl}>
              <DashboardOutlined /> {f('dashboard')}
            </Link>
          </li>
          <li>
            <Link href={mdcAccountUrl} target="_blank" rel="noopener noreferrer">
              <UserOutlined /> {f('profile')}
            </Link>
          </li>
          <li>
            <Link href={`${theboatAppUrl}/activity-log`}>
              <LaptopOutlined /> {f('activity_log')}
            </Link>
          </li>
          <li>
            <Link href={`${mdcAccountUrl}/settings`} target="_blank" rel="noopener noreferrer">
              <SettingOutlined /> {f('settings')}
            </Link>
          </li>
          <li onClick={showDrawer}>
            <a>
              <QuestionOutlined /> {f('help_center')}
            </a>
          </li>
          <li onClick={handleLogout}>
            <a>
              <LogoutOutlined /> {f('log_out')}
            </a>
          </li>
        </ul>
      </div>

      <MainDrawer visible={helpCenterDrawer} onClose={onClose} closable={false}>
        <HelpCenterMenu isDrawer app="tba" />
      </MainDrawer>
    </>
  );
};

export default UserDrawerNav;
